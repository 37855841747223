import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../../../common/Footer";
import AvailableOnMobile from "../../../common/available-on-mobile";

import sendPaymentLink from "../../../assets/img/send-payment-link.png";
import sendPaymentLinkMobile from "../../../assets/img/send-payment-link-mobile.png";
import customizeReminder from "../../../assets/img/customize-reminder.png";
import customizeReminderMobile from "../../../assets/img/customize-reminder-mobile.png";
import scheduleReminderNew from "../../../assets/img/schedule-reminder-new.png";
import scheduleReminderMobile from "../../../assets/img/schedule-reminder-mobile.png";
import sendRemindersNew from "../../../assets/img/send-reminder-new.png";
import sendRemindersMobile from "../../../assets/img/send-reminder-mobile.png";
import PaymentCollectionBanner from "../../../assets/img/payment-collection-banner.png";
import PaymentCollectionBannerMobile from "../../../assets/img/payment-collection-banner-mobile.png";

import sendPaymentLinkLazy from "../../../assets/img/webp/send-payment-link.webp";
import sendPaymentLinkMobileLazy from "../../../assets/img/webp/send-payment-link-mobile.webp";
import customizeReminderLazy from "../../../assets/img/webp/customize-reminder.webp";
import customizeReminderMobileLazy from "../../../assets/img/webp/customize-reminder-mobile.webp";
import scheduleReminderNewLazy from "../../../assets/img/webp/schedule-reminder-new.webp";
import scheduleReminderMobileLazy from "../../../assets/img/webp/schedule-reminder-mobile.webp";
import sendRemindersNewLazy from "../../../assets/img/webp/send-reminder-new.webp";
import sendRemindersMobileLazy from "../../../assets/img/webp/send-reminder-mobile.webp";
import PaymentCollectionBannerLazy from "../../../assets/img/webp/payment-collection-banner.webp";
import PaymentCollectionBannerMobileLazy from "../../../assets/img/webp/payment-collection-banner-mobile.webp";

import { Helmet } from "react-helmet";

const PaymentCollectionReminder = () => {
  useEffect(() => {
    document.title = "Tijoree";
    let descMeta = document.querySelector('meta[name="description"]');
    descMeta.setAttribute(
      "content",
      "Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking."
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    AOS.init({
      easing: "ease-out-back",
      duration: 700,
      once: true,
      // mirror: true,
      disable: "mobile",
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> Payment Collection Reminder | Tijoree</title>
        <meta
          name="description"
          content=" Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking."
        />
      </Helmet>
      <div className="page-wrapper">
        <section
          id="inner-top-section"
          className="inner-top-section grey-bg-2 payment-collection-banner"
        >
          <div className="container-md">
            <div className="inner-content">
              <div className="row">
                <div className="col-md-10">
                  <div className="page-title">Payment Collection Reminder</div>
                  <div className="row">
                    <div className="col-md-5">
                      <h2 className="size40">
                        Ensure on-time payments every time
                      </h2>
                      <div className="action-btns d-lg-block d-md-block d-sm-none">
                        <Link to={"/form"} className="button btn-yellow">
                          Sign Up
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                      <p className="size20">
                        Send payment reminders to your customers and simplify
                        your invoice collections.
                      </p>
                      <div className="action-btns d-lg-none d-md-none d-sm-block">
                        <Link to={"/form"} className="button btn-yellow">
                          Sign Up
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overview-img text-center">
              <picture>
                <source
                  type="image/webp"
                  srcSet={PaymentCollectionBannerLazy}
                ></source>
                <source
                  type="image/png"
                  srcSet={PaymentCollectionBanner}
                ></source>
                <img
                  className="desktop"
                  alt="Payment Collection"
                  src={PaymentCollectionBanner}
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  srcSet={PaymentCollectionBannerMobileLazy}
                ></source>
                <source
                  type="image/png"
                  srcSet={PaymentCollectionBannerMobile}
                ></source>
                <img
                  className="mobile"
                  alt="Payment Collection"
                  src={PaymentCollectionBannerMobile}
                />
              </picture>
            </div>
          </div>
        </section>

        {/* 
        <section id="about-reminder" className="about-reminder">
          <div className="container-md">
            <div className="about-reminder-inner">
              <div className="row">
                <div className="col-md-4">
                  <h4 className='size28'>What is Payment Collection Reminder?</h4>
                </div>
                <div className="col-md-7">
                  <p className='size20'>With Payment Collection Reminder you can send reminders to your customers with invoice & get paid faster.
                    Along with the reminder send a payment link where your customers pay money online directly.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        <section
          id="payment-schedule-new"
          className="payment-schedule-new collection-page"
        >
          <div className="container-md">
            <div className="row align-items-center hover-out">
              <div className="col-md-6 mobile-order-2">
                <div className="payment-text">
                  <h3 className="size40">
                    Customize your reminder based on purpose
                  </h3>
                </div>
              </div>
              <div className="col-md-6  mobile-order-1">
                <div className=" payment-image">
                  <div className="image-wrap">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={customizeReminderLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={customizeReminder}
                      ></source>
                      <img
                        className="desktop hover-img"
                        alt="Customize your Reminder"
                        src={customizeReminder}
                      />
                    </picture>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={customizeReminderMobileLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={customizeReminderMobile}
                      ></source>
                      <img
                        className="mobile hover-img"
                        alt="Customize your Reminder"
                        src={customizeReminderMobile}
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
            <div className="scremindernew">
              <div className="row align-items-center hover-box">
                <div className="col-md-6 get-paid-collection  mobile-order-2">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={scheduleReminderNewLazy}
                    ></source>
                    <source
                      type="image/png"
                      srcSet={scheduleReminderNew}
                    ></source>
                    <img
                      className="desktop hover-img"
                      alt="Schedule to send reminders"
                      src={scheduleReminderNew}
                    />
                  </picture>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={scheduleReminderMobileLazy}
                    ></source>
                    <source
                      type="image/png"
                      srcSet={scheduleReminderMobile}
                    ></source>
                    <img
                      className="mobile hover-img"
                      alt="Schedule to send reminders"
                      src={scheduleReminderMobile}
                    />
                  </picture>
                </div>
                <div className="col-md-5  mobile-order-1">
                  <h3 className="size40">
                    Schedule to send reminders on specific days and at your
                    desired frequency
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <section id="get-paid" className="get-paid pos screminder">
          <div className="container-md">
            <div className="main-box hover-box">
              <div className="inner-content">
                <div className="row">
                  <div className="col-md-6 get-paid-collection">
                    <img className="hover-img desktop" src={scheduleReminderNew} alt="Get Paid" />
                    <img className="hover-img mobile" src={scheduleReminderMobile} alt="Get Paid" />
                  </div>
                  <div className="col-md-5">
                    <h2 className='size40'>Schedule your Reminders</h2>
                    <p className='size20'>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
         */}

        <section
          id="send-customize-reminder"
          className="send-customize-reminder new"
        >
          <div className="container-md">
            <div className="send-reminder hover-box">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 mobile-order-2">
                  <div className="business-invoice">
                    <h3 className="size40">Send your vendors reminders</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mobile-order-1">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={sendRemindersNewLazy}
                    ></source>
                    <source type="image/png" srcSet={sendRemindersNew}></source>
                    <img
                      className="desktop hover-img"
                      alt="Send Reminders"
                      src={sendRemindersNew}
                    />
                  </picture>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={sendRemindersMobileLazy}
                    ></source>
                    <source
                      type="image/png"
                      srcSet={sendRemindersMobile}
                    ></source>
                    <img
                      className="mobile hover-img"
                      alt="Send Reminders"
                      src={sendRemindersMobile}
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="payment-schedule-new"
          className="payment-schedule-new collection-page nobg"
        >
          <div className="container-md">
            <div className="row align-items-center hover-out">
              <div className="col-md-7">
                <div className=" payment-image">
                  <div className="image-wrap">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={sendPaymentLinkLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={sendPaymentLink}
                      ></source>
                      <img
                        className="desktop hover-img"
                        alt="Send Payment Link"
                        src={sendPaymentLink}
                      />
                    </picture>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={sendPaymentLinkMobileLazy}
                      ></source>
                      <source
                        type="image/png"
                        srcSet={sendPaymentLinkMobile}
                      ></source>
                      <img
                        className="mobile hover-img"
                        alt="Send Payment Link"
                        src={sendPaymentLinkMobile}
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="payment-text">
                  <h3 className="size40">
                    Send payment links to your customers: get paid directly
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* 
        <section id="send-customize-reminder" className="send-customize-reminder">
            <div className="container-md">
                <div className="row">
                   <div className="col-lg-6">
                     <div className="send-reminder hover-box">
                        <div className="row">
                           <div className="col-md-6 d-flex justify-content-center align-items-end overdue-invoice">
                             <img className=" desktop hover-img" src={OverdueInvoice} alt="Overdue Invoice"/>
                             <img className="mobile hover-img" src={OverdueInvoiceMobile} alt="Overdue Invoice"/>
                           </div>
                           <div className="col-md-6 pl-0 business-invoice">
                              <img className="desktop hover-img" src={BusinessInvoiceList} alt="Business Invoice List"/>
                              <h4>Send Reminders</h4>
                              <p>Porttitor enim eget suspendisse corper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                           </div>
                        </div>
                     </div>
                   </div>

                   <div className="col-lg-6">
                     <div className="customize-reminder hover-box">
                        <div className="row">
                           <div className="col-md-6 d-flex justify-content-center align-items-end">
                             <img className="desktop hover-img" src={ReminderScript} alt="Reminder Script"/>
                             <img className="mobile hover-img" src={ReminderScriptMobile} alt="Reminder Script"/>
                           </div>
                           <div className="col-md-6 pl-0">
                              <div className="content-left">
                              <h4>Customize your Reminder</h4>
                              <p>Porttitor enim eget suspendisse corper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                   </div>
                </div>
            </div>
        </section>
       */}

        {/*
        <section id="schedule-payment" className="schedule-payment">
            <div className="container-md">
            <div className="reminder-payments">
              <div className="row">
                <div className="col-md-6">
                  <div className="reminder hover-box">
                    <img className="desktop hover-img" src={ReminderTable} alt="Reminder" />
                    <img className="mobile hover-img" src={ReminderTableMobile} alt="Reminder" />
                  </div>
                  <div className="card-inner-block">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <h6>Schedule your Reminders</h6>
                      </div>
                      <div className="col-md-8">
                        <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="payment hover-box">
                    <img className="desktop hover-img" src={PaymentLink} alt="Payment Link" />
                    <img className="mobile hover-img" src={PaymentLinkMobile} alt="Payment Link" />
                  </div>
                  <div className="card-inner-block">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <h6>Payment Link</h6>
                      </div>
                      <div className="col-md-8">
                        <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </section>
      */}

        {/* 
        <section id="faq-section" className="faq-section">
          <div className="container-md">
            <div className="faq-head">
              <h1>FAQ</h1>
              <p>Frequently Asked Questions</p>
            </div>
            <div class="accordion" id="accountOpening">
              <div class="accordion-item">
                <h4 class="accordion-header" id="title1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapseOne" data-bs-parent="#accountOpening">What is the account opening process?</button></h4>
                <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="title1" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>Account opening of current account will be as per the existing process. Existing Account Opening Form (AOF) needs to be used for account opening. KYC &amp; related documents as applicable basis entity type.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapseTwo" data-bs-parent="#accountOpening">What is a One Person Company Account?</button></h4>
                <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="title2" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>The concept of OPC allows a single person to run a company limited by shares, and Sole proprietorship means an entity where it is run and owned by one individual and where there is no distinction between the owner and the business</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title3"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseThree" data-bs-parent="#accountOpening">Is AADHAAR Mandatory for Authorized Signatories?</button></h4>
                <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="title3" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>AADHAAR is Mandatory for all Authorized Signatories</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title4"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree" data-bs-parent="#accountOpening">I do not have a physical Pan Card, but I have a pan card number.?</button></h4>
                <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="title4" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>If a PAN card is not available, a printout of the PAN for the account holder may be taken from <a href="http://incometaxindia.gov.in/" target="_blank">http://incometaxindia.gov.in/</a> - the printout must be stamped “Downloaded/Verified at Branch” by Bank officials.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        <section id="cta-bg-curve" class="cta-bg-curve inner text-center">
          <div className="container-md">
            <h4>Join Tijoree & Grow your Business</h4>
            <Link className="button btn-purple" to={"/form"}>
              Get Started
            </Link>
          </div>
        </section>

        <section id="available-versions" className="available-versions">
          <div className="container-md">
            <AvailableOnMobile></AvailableOnMobile>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default PaymentCollectionReminder;
