import { City, State } from "country-state-city";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import ProgressiveImage from "react-progressive-graceful-image";
import axios from "axios";

import CorporateCreditcardTab from "../../assets/img/corporate-credit-card-tab.svg";

import formImgRightDefault from "../../assets/img/form-right-img-default.png";
import formImgRightDefaultLazy from "../../assets/img/corporate-credit-card-tab.svg";

// Images imported from assets

// import Footer from '../../common/Footer';

const initialState = {
  fullName: "",
  mail: "",
  phoneNumber: "",
  company: "",
  state: "",
  city: "",
};

const ContactForm = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialState);
  const [formSubmit, setFormSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();

  const handleOnChange = (e) => {
    let values = {
      [e.target.name]:
        e.target.name === "mail"
          ? e.target.value.toLowerCase()
          : e.target.name === "phoneNumber"
          ? e.target.value.replace(/[^0-9]+/g, "")
          : e.target.value,
    };
    setFormValues({ ...formValues, ...values });
  };
  useEffect(() => {
    setStateData(State.getStatesOfCountry("IN"));
  }, []);

  // useEffect(() => {
  //   setCityData(City.getCitiesOfState("IN", state?.isoCode));
  // }, []);

  // useEffect(() => {
  //   stateData && setState(stateData[0]);
  // }, [stateData]);

  // useEffect(() => {
  //   cityData && setCity(cityData[1]);
  // }, [cityData]);

  const handleState = (value) => {
    setFormValues({ ...formValues, state: value });
    console.log("value", value);
    const stateCode = stateData.find((el) => el.name === value);
    const allcities = City.getCitiesOfState("IN", stateCode.isoCode);
    setCityData(allcities.sort());
  };

  const checkValidation = (data) => {
    let err = {};
    for (const key in data) {
      if (data[key].length === 0) {
        err = { ...err, [key]: `${key} is a required field` };
      } else if (
        key === "mail" &&
        !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(.\.[a-zA-Z]{2,5}){1,2}$/.test(
          data["mail"]
        )
      ) {
        err = {
          ...err,
          mail: `Please enter a valid mail address`,
        };
      } else if (
        key === "phoneNumber" &&
        !/^[6-9]\d{9}$/.test(data["phoneNumber"])
      ) {
        err = {
          ...err,
          phoneNumber: `Please enter a valid phone no.`,
        };
      }
    }
    setError(err);
  };

  const [consent, setConsent] = useState(false);

  const handleConsentChange = () => {
    setConsent(!consent);
  };

  useEffect(() => {
    document.title = "Tijoree";
    let descMeta = document.querySelector('meta[name="description"]');
    descMeta.setAttribute(
      "content",
      "Discover cutting-edge Neo Banking services tailored for SMEs and startups. Contact us today for innovative financial solutions and seamless customer support. Start maximizing your business potential with our advanced digital banking platform."
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log("first scroll");
  }, []);

  useEffect(() => {
    checkValidation(formValues);
  }, [formSubmit, formValues]);

  const handleFormSubmit = (e) => {
    console.log(formValues);
    e.preventDefault();
    setFormSubmit(true);
    if (Object.keys(error).length) return;
    requestDemo(formValues);
  };

  const requestDemo = (values) => {
    console.log("values", values);
    setLoading(true);
    const data = JSON.stringify(values);
    const config = {
      method: "post",
      url: "https://apidev.tijoree.money/current-account/demo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        navigate("/thankyou", { replace: false });
        // document.querySelector(".rightContainer").classList.add("success");
        // document.querySelector(".rightContainer").classList.remove("error");
        // setTimeout(function () {
        //   handleModalClose();
        // }, 3500);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        // document.querySelector(".rightContainer").classList.add("error");
        // document.querySelector(".rightContainer").classList.remove("success");
        // setTimeout(function () {
        //   handleModalClose();
        // }, 3500);
      });
  };

  // const handleModalClose = () => {
  //   setFormSubmit(false);
  //   setFormValues(initialState);
  //   document.querySelector(".rightContainer").classList.remove("error");
  //   document.querySelector(".rightContainer").classList.remove("success");
  // };

  return (
    <React.Fragment>
      <Helmet>
        <title> Tijoree </title>
        <meta
          name="description"
          content="Discover cutting-edge Neo Banking services tailored for SMEs and startups. Contact us today for innovative financial solutions and seamless customer support. Start maximizing your business potential with our advanced digital banking platform."
        />
      </Helmet>
      <>
        <div className="page-wrapper">
          <section id="contact-form">
            <div className="container-md">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-left">
                    <div className="form-main">
                      <h4>Book a free intro call with a product expert</h4>
                      <p>
                        Please answer some quick questions so that we can assign
                        you the best expert
                      </p>
                      <div className="form-group">
                        <span className="inputLabel">Enter full name</span>
                        <input
                          name="fullName"
                          placeholder="Enter full name"
                          type="text"
                          className="form-control"
                          value={formValues.fullName}
                          onChange={(e) => handleOnChange(e)}
                        />
                        {formSubmit &&
                          error.hasOwnProperty("fullName") &&
                          error.fullName && (
                            <div className="invalid-feedback">
                              {error.fullName}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <span className="inputLabel">Email address</span>
                        <input
                          name="mail"
                          placeholder="Enter email address"
                          type="text"
                          className="form-control"
                          value={formValues.mail}
                          onChange={(e) => handleOnChange(e)}
                        />
                        {formSubmit &&
                          error.hasOwnProperty("mail") &&
                          error.mail && (
                            <div className="invalid-feedback">{error.mail}</div>
                          )}
                      </div>
                      <div className="form-group">
                        <span className="inputLabel">
                          What is the name of your company/store?
                        </span>
                        <input
                          name="company"
                          placeholder="Enter company name"
                          type="text"
                          className="form-control"
                          value={formValues.company}
                          onChange={(e) => handleOnChange(e)}
                        />
                        {formSubmit &&
                          error.hasOwnProperty("company") &&
                          error.company && (
                            <div className="invalid-feedback">
                              {error.company}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <span className="inputLabel">Phone Number</span>
                        <div className="phone-wrapper">
                          <input
                            name="phoneNumber"
                            type="text"
                            className="form-control"
                            value={formValues.phoneNumber}
                            onChange={(e) => handleOnChange(e)}
                            maxlength="10"
                          />
                          {formSubmit &&
                            error.hasOwnProperty("phoneNumber") &&
                            error.phoneNumber && (
                              <div className="invalid-feedback">
                                {error.phoneNumber}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <span className="inputLabel">State</span>
                            <div className="select-wrapper">
                              <select
                                onChange={(e) => {
                                  handleOnChange(e);
                                  handleState(e.target.value);
                                }}
                                name="state"
                                value={formValues.state}
                                className="form-control"
                                placeholder="Select"
                              >
                                <option value="" hidden>
                                  Select
                                </option>
                                {stateData?.map((getState, index) => (
                                  <option key={index} value={getState?.name}>
                                    {getState?.name}
                                  </option>
                                ))}
                              </select>

                              {formSubmit &&
                                error.hasOwnProperty("state") &&
                                error.state && (
                                  <div className="invalid-feedback">
                                    {error.state}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <span className="inputLabel">City</span>
                            <div className="select-wrapper">
                              <select
                                onChange={(e) => handleOnChange(e)}
                                name="city" // Set the value to the selected city
                                className="form-control"
                                placeholder="Select"
                              >
                                <option value="" hidden>
                                  Select
                                </option>
                                {cityData?.map((getCity, index) => (
                                  <option key={index} value={getCity?.name}>
                                    {getCity?.name}
                                  </option>
                                ))}
                              </select>

                              {formSubmit &&
                                error.hasOwnProperty("city") &&
                                error.city && (
                                  <div className="invalid-feedback">
                                    {error.city}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <span className="inputLabel">Message*</span>
                        <textarea
                          name="message"
                          className="form-control textareaField"
                          placeholder="Type your message"
                          value={formValues.message}
                          onChange={(e) => handleOnChange(e)}
                        ></textarea>
                        {formSubmit &&
                          error.hasOwnProperty("message") &&
                          error.message && (
                            <div className="invalid-feedback">
                              {error.message}
                            </div>
                          )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <span className="inputLabel"></span>
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="consentCheckbox"
                                checked={consent}
                                onChange={handleConsentChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="consentCheckbox"
                              >
                                By clicking on 'continue' you agree to Tijoree{" "}
                                <Link to={"/terms-conditions"}>
                                  terms of service
                                </Link>
                                , and acknowledge our{" "}
                                <Link to={"/privacy-policy"}>
                                  privacy policy
                                </Link>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <span className="inputLabel"></span>
                          <div className="form-group">
                            <label className="form-check-label">
                              I hereby grant my consent to receive SMS/messages,
                              promotional materials and informational updates
                              from Tijoree.
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="submit-btn">
                        <Link
                          to={"#"}
                          className="button btn-purple medium"
                          onClick={(e) => handleFormSubmit(e)}
                        >
                          {/* Request for Demo */}
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span>Loading...</span>
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-right text-center">
                    <div className="img-container">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={formImgRightDefaultLazy}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={formImgRightDefault}
                        ></source>
                        <img
                          className=""
                          alt="contact"
                          src={formImgRightDefault}
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Footer /> */}
        </div>
      </>
    </React.Fragment>
  );
};

export default ContactForm;
