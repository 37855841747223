import { Link, useLocation, useNavigate } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

// Images imported from assets
// import call from '../../assets/img/call.svg';
// import smsTracking from '../../assets/img/sms-tracking.svg';
// import location from '../../assets/img/location.svg';
// import maskGroup from '../../assets/img/Mask group.svg';
// import close from '../../assets/img/close-circle.svg';
// import formImgRightDefault from '../../assets/img/form-right-img-default.png';

import ThankyouIcon from "../../assets/img/thankyou.svg";
import CurrentAccountTab from "../../assets/img/current-account-tab.png";
import CorporateCreditcardTab from "../../assets/img/corporate-credit-card-tab.svg";
import InstorePaymentTab from "../../assets/img/instore-payment-tab.png";
import PaymentCollectionReminderTab from "../../assets/img/payment-collection-reminder-tab.png";

import CurrentAccountTabLazy from "../../assets/img/webp/current-account-tab.webp";
import CorporateCreditcardTabLazy from "../../assets/img/corporate-credit-card-tab.svg";
import InstorePaymentTabLazy from "../../assets/img/webp/instore-payment-tab.webp";
import PaymentCollectionReminderTabLazy from "../../assets/img/webp/payment-collection-reminder-tab.webp";

import React, { useEffect, useState } from "react";
// import Footer from '../../common/Footer';
import axios from "axios";
import { Helmet } from "react-helmet";

const Thankyou = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Tijoree";
    let descMeta = document.querySelector('meta[name="description"]');
    descMeta.setAttribute(
      "content",
      "Discover cutting-edge Neo Banking services tailored for SMEs and startups. Contact us today for innovative financial solutions and seamless customer support. Start maximizing your business potential with our advanced digital banking platform."
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log("first scroll");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> Thank You | Neo Banking Services For SMEs and Startup</title>
        <meta
          name="description"
          content="Discover cutting-edge Neo Banking services tailored for SMEs and startups. Contact us today for innovative financial solutions and seamless customer support. Start maximizing your business potential with our advanced digital banking platform."
        />
      </Helmet>
      <>
        <div className="page-wrapper">
          <section id="contact-form" className="thankyou-page">
            <div className="container-md">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-left">
                    <div className="main-heading">
                      <img width="45" src={ThankyouIcon} alt="Thank You" />
                      <h3>Thank you for your interest</h3>
                      <div className="sub-title">
                        Our product expert will get in touch with you soon
                      </div>
                      <Link
                        className="button btn-white medium d-lg-none return-home-btn"
                        to={"/current-account"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.66667 11.3333H10.5C11.4283 11.3333 12.3185 10.9646 12.9749 10.3082C13.6313 9.65183 14 8.76159 14 7.83333C14 6.90508 13.6313 6.01484 12.9749 5.35846C12.3185 4.70208 11.4283 4.33333 10.5 4.33333H3.66667M5.33333 2L3 4.33333L5.33333 6.66667"
                            stroke="#4600A9"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        Return to Home
                      </Link>
                    </div>
                    <div className="inner-title">
                      Get to know more about our Products & Features
                    </div>

                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      {/* <li className="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="currentAccount-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#currentAccount-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="currentAccount-tab-pane"
                          aria-selected="true"
                        >
                          <h5>
                            Current Account{" "}
                            <span className="knowmore">Know More</span>{" "}
                          </h5>
                          <p>
                            Best Current Account in India. Make transactions &
                            GST abd TDS payment with ease.
                          </p>
                        </button>
                      </li> */}
                      <li className="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="corporate-cc-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#corporate-cc-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="corporate-cc-tab-pane"
                          aria-selected="true"
                        >
                          <h5>
                            Corporate Credit Card{" "}
                            <span className="knowmore">Know More</span>
                          </h5>
                          <p>
                            Business Credit Card for effortless on demand
                            financing & paying your vendor to get an extended
                            credit period.
                          </p>
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="instorePayment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#instorePayment-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="instorePayment-tab-pane"
                          aria-selected="false"
                        >
                          <h5>
                            In-Store UPI & Card Payments{" "}
                            <span className="knowmore">Know More</span>
                          </h5>
                          <p>
                            Get Tijoree POS to collect UPI & POS Payments in
                            your store from your customers.
                          </p>
                        </button>
                      </li> */}
                      {/* <li className="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="collectionReminder-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#collectionReminder-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="collectionReminder-tab-pane"
                          aria-selected="false"
                        >
                          <h5>
                            Payment Collection Reminder{" "}
                            <span className="knowmore">Know More</span>
                          </h5>
                          <p>
                            Send you Customers Payment Reminders for pending
                            payment & get paid directly with Payment Links.
                          </p>
                        </button>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-right text-center">
                    <div className="tab-content" id="myTabContent">
                      {/* <div
                        className="tab-pane fade show "
                        id="currentAccount-tab-pane"
                        role="tabpanel"
                        aria-labelledby="currentAccount-tab"
                      >
                        <div className="tab-img">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={CurrentAccountTabLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={CurrentAccountTab}
                            ></source>
                            <img
                              className=""
                              alt="Current Account"
                              src={CurrentAccountTab}
                            />
                          </picture>
                        </div>
                        <Link
                          className="button btn-white medium"
                          to={"/products/current-account"}
                        >
                          Know More
                        </Link>
                      </div> */}
                      <div
                        className="tab-pane active"
                        id="corporate-cc-tab-pane"
                        role="tabpanel"
                        aria-labelledby="corporate-cc-tab"
                      >
                        <div className="tab-img">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={CorporateCreditcardTabLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={CorporateCreditcardTab}
                            ></source>
                            <img
                              className=""
                              alt="Corporate Credit Card"
                              src={CorporateCreditcardTab}
                            />
                          </picture>
                        </div>
                        {/* <button
                          className="button btn-white medium"
                          onClick={() => {
                            navigate("/", {
                              state: { id: "business-cc" },
                            });
                          }}
                        >
                          <span className="arrow"></span>
                          Know More
                        </button> */}
                      </div>
                      {/* <div
                        className="tab-pane fade"
                        id="instorePayment-tab-pane"
                        role="tabpanel"
                        aria-labelledby="instorePayment-tab"
                      >
                        <div className="tab-img">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={InstorePaymentTabLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={InstorePaymentTab}
                            ></source>
                            <img
                              className=""
                              alt="In Store Payment"
                              src={InstorePaymentTab}
                            />
                          </picture>
                        </div>
                        <Link
                          className="button btn-white medium"
                          to={"/products/pos"}
                        >
                          Know More
                        </Link>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="collectionReminder-tab-pane"
                        role="tabpanel"
                        aria-labelledby="collectionReminder-tab"
                      >
                        <div className="tab-img">
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={PaymentCollectionReminderTabLazy}
                            ></source>
                            <source
                              type="image/png"
                              srcSet={PaymentCollectionReminderTab}
                            ></source>
                            <img
                              className=""
                              alt="Payment Collection Reminder"
                              src={PaymentCollectionReminderTab}
                            />
                          </picture>
                        </div>
                        <Link
                          className="button btn-white medium"
                          to={"/products/payment-collection"}
                        >
                          Know More
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </React.Fragment>
  );
};

export default Thankyou;
