import React, { useEffect } from "react";
import Footer from "../../common/Footer";
import { Helmet } from "react-helmet";

const Faq = () => {
  useEffect(() => {
    document.title = "Tijoree";
    // let descMeta=document.querySelector('meta[name="description"]')
    // descMeta.setAttribute("content", 'A B2B payments platform to optimize all of your business spends that combines the power of commercial credit cards and customisable tech to help you optimise your treasury returns and working capital.');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> About Us | Corporate Business Banking |Tijoree</title>
        <meta
          name="description"
          content="Discover Tijoree, your trusted partner for corporate business banking solutions. With a focus on delivering exceptional financial services, we empower businesses with cutting-edge digital banking tools and personalized support. Learn more about our comprehensive suite of services and how Tijoree can fuel your business growth. Contact us today and experience a new era of corporate banking excellence."
        />
      </Helmet>
      <div className="page-wrapper faqpage">
        <section id="faq-banner">
          <div className="container-md">
            <div className="banner-text">
              <h1>Frequently Asked Questions</h1>
            </div>
          </div>
        </section>

        <section id="faq-section">
          <div className="container-md">
            <div className="row justify-content-between">
              <div className="col-md-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="faq-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#faq-tab-1"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Current Account
                    </button>
                  </li>
                  {/*
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="faq-tab2" data-bs-toggle="tab" data-bs-target="#faq-tab-2" type="button" role="tab" aria-controls="faq-tab-2" aria-selected="false">Corporate Credit Card</button>
                  </li>
                */}
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="faq-tab3"
                      data-bs-toggle="tab"
                      data-bs-target="#faq-tab-3"
                      type="button"
                      role="tab"
                      aria-controls="faq-tab-3"
                      aria-selected="false"
                    >
                      Payment Collection Reminder
                    </button>
                  </li>
                  {/*
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="faq-tab4" data-bs-toggle="tab" data-bs-target="#faq-tab-4" type="button" role="tab" aria-controls="faq-tab-4" aria-selected="false">Payment Links</button>
                  </li>
                  */}
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="faq-tab5"
                      data-bs-toggle="tab"
                      data-bs-target="#faq-tab-5"
                      type="button"
                      role="tab"
                      aria-controls="faq-tab-5"
                      aria-selected="false"
                    >
                      POS
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="faq-tab-1"
                    role="tabpanel"
                    aria-labelledby="faq-tab1"
                  >
                    <div className="accordion" id="currentAccount">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq2">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_2"
                                aria-expanded="false"
                                aria-controls="current_2"
                                data-bs-parent="#currentAccount"
                              >
                                What is the difference between Single Pay-out &
                                Bulk Pay-out features?
                              </button>
                            </h4>
                            <div
                              id="current_2"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq2"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  A single pay-out is when payments or transfers
                                  are made individually, for each recipient.
                                </p>
                                <p>
                                  Payments are processed in bulk in a single
                                  batch. These payments are typically similar in
                                  nature, such as salary payments to multiple
                                  employees or vendor payments to several
                                  suppliers.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq22">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_22"
                                aria-expanded="false"
                                aria-controls="current_22"
                                data-bs-parent="#currentAccount"
                              >
                                Are there any charges associated with using the
                                Single or Bulk Pay-outs option?
                              </button>
                            </h4>
                            <div
                              id="current_22"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq22"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  There are no charges for single payout and
                                  bulk payout.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq3">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_3"
                                aria-expanded="false"
                                aria-controls="current_3"
                                data-bs-parent="#currentAccount"
                              >
                                Is there any cooling period after adding
                                beneficiaries for payments?
                              </button>
                            </h4>
                            <div
                              id="current_3"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq3"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>No cooling period</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq4">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_4"
                                aria-expanded="false"
                                aria-controls="current_4"
                                data-bs-parent="#currentAccount"
                              >
                                What is the transaction limit for making NEFT &
                                RTGS payments?
                              </button>
                            </h4>
                            <div
                              id="current_4"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq4"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  No transaction limit for NEFT & RTGS payments
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq5">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_5"
                                aria-expanded="false"
                                aria-controls="current_5"
                                data-bs-parent="#currentAccount"
                              >
                                What are scheduled payments?
                              </button>
                            </h4>
                            <div
                              id="current_5"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq5"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  Scheduled payments, also called recurring
                                  payments or automatic payments, are financial
                                  transactions that happen based on a
                                  predetermined date & time chosen by the payer.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq6">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_6"
                                aria-expanded="false"
                                aria-controls="current_6"
                                data-bs-parent="#currentAccount"
                              >
                                How is the current account average monthly
                                balance calculated?
                              </button>
                            </h4>
                            <div
                              id="current_6"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq6"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  a straightforward formula to determine the
                                  account holder's Monthly Average Balance
                                  (MAB):
                                </p>
                                <p>
                                  MAB = (Total of end-of-the-day closing
                                  balances) / (number of days in the month)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq7">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_7"
                                aria-expanded="false"
                                aria-controls="current_7"
                                data-bs-parent="#currentAccount"
                              >
                                Can I view all my statements in Tijoree?
                              </button>
                            </h4>
                            <div
                              id="current_7"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq7"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  You can view all statements related to your
                                  linked Current Account.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq8">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_8"
                                aria-expanded="false"
                                aria-controls="current_8"
                                data-bs-parent="#currentAccount"
                              >
                                When I add a beneficiary to my Current Account,
                                can I use Tijoree money to make a Payout
                                immediately?
                              </button>
                            </h4>
                            <div
                              id="current_8"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq8"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  From Tijoree money, you can make an instant
                                  payout.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq9">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_9"
                                aria-expanded="false"
                                aria-controls="current_9"
                                data-bs-parent="#currentAccount"
                              >
                                What is a current account?
                              </button>
                            </h4>
                            <div
                              id="current_9"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq9"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  An account with a current balance permits a
                                  higher number of transactions and services. It
                                  is built for all kinds of businesses - SMEs,
                                  Startups, and Enterprises. It is typically
                                  used for day-to-day financial activities and
                                  provides easy access to funds.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq10">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_10"
                                aria-expanded="false"
                                aria-controls="current_10"
                                data-bs-parent="#currentAccount"
                              >
                                Is a Current Account mandatory for business?
                              </button>
                            </h4>
                            <div
                              id="current_10"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq10"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  It is not mandatory for businesses to open a
                                  current account to operate. However, given the
                                  multiple benefits of a current account such as
                                  unlimited transactions, separation from
                                  personal finances and banking features built
                                  specifically for business needs, opening a
                                  current account is highly recommended.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="current_faq11">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#current_11"
                                aria-expanded="false"
                                aria-controls="current_11"
                                data-bs-parent="#currentAccount"
                              >
                                What documents are needed for opening a current
                                account?
                              </button>
                            </h4>
                            <div
                              id="current_11"
                              className="accordion-collapse collapse"
                              aria-labelledby="current_faq11"
                              data-bs-parent="#currentAccount"
                            >
                              <div className="accordion-body">
                                <p>
                                  Please find the below list of KYC documents
                                  required for opening a current account.
                                </p>
                                <br></br>
                                <p>
                                  Yes bank <br></br>
                                  <a
                                    target="_blank"
                                    href="https://drive.google.com/drive/folders/1py9mR2QAK6Dnd6iM2x6MmDBYM-8xw6MA"
                                  >
                                    https://drive.google.com/drive/folders/1py9mR2QAK6Dnd6iM2x6MmDBYM-8xw6MA
                                  </a>
                                </p>
                                <p>
                                  <br></br>
                                  Unity Small Finance bank.
                                  <br></br>
                                  <a
                                    target="_blank"
                                    href="https://drive.google.com/drive/folders/1py9mR2QAK6Dnd6iM2x6MmDBYM-8xw6MA"
                                  >
                                    https://drive.google.com/drive/folders/1py9mR2QAK6Dnd6iM2x6MmDBYM-8xw6MA
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="faq-tab-3"
                    role="tabpanel"
                    aria-labelledby="faq-tab3"
                  >
                    <div className="accordion" id="collectionReminder">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_1">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq1"
                                aria-expanded="true"
                                aria-controls="collection_faq1"
                                data-bs-parent="#collectionReminder"
                              >
                                How does Payment Collection Reminder Helps me
                              </button>
                            </h4>
                            <div
                              id="collection_faq1"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_1"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  Tijoree will assist you in sending reminders
                                  via email, phone, or SMS, which will increase
                                  your available cash inflow. It additionally
                                  helps in decreasing the time required for
                                  payment collection through the use of
                                  recurring reminders.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_2">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq2"
                                aria-expanded="true"
                                aria-controls="collection_faq2"
                                data-bs-parent="#collectionReminder"
                              >
                                How can I Sign-up for PCR?
                              </button>
                            </h4>
                            <div
                              id="collection_faq2"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_2"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  For PCR registration, please either click on
                                  this link:{" "}
                                  <a href="https://app.tijoree.money/sign-up">
                                    Sign up
                                  </a>
                                  , or get in touch with us at
                                  support@tijoree.money or call us at +91
                                  9152020329.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_3">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq3"
                                aria-expanded="true"
                                aria-controls="collection_faq3"
                                data-bs-parent="#collectionReminder"
                              >
                                How many vendors/businesses can I add in my
                                account?
                              </button>
                            </h4>
                            <div
                              id="collection_faq3"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_3"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  There is no limit on vendors/businesses to
                                  send them Reminders. Users can add multiple
                                  vendors/businesses.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_4">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq4"
                                aria-expanded="true"
                                aria-controls="collection_faq4"
                                data-bs-parent="#collectionReminder"
                              >
                                How many Reminders can be sent in one day?
                              </button>
                            </h4>
                            <div
                              id="collection_faq4"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_4"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  With Tijoree Payment Collection Reminders you
                                  can send any number of reminders everyday.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_5">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq5"
                                aria-expanded="true"
                                aria-controls="collection_faq5"
                                data-bs-parent="#collectionReminder"
                              >
                                Is there an option to send Recurring Reminders?
                              </button>
                            </h4>
                            <div
                              id="collection_faq5"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_5"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  Yes, users can send recurring reminders from
                                  the recurring reminder tab.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_6">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq6"
                                aria-expanded="true"
                                aria-controls="collection_faq6"
                                data-bs-parent="#collectionReminder"
                              >
                                How can I track the invoices for which payment
                                has been made?
                              </button>
                            </h4>
                            <div
                              id="collection_faq6"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_6"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  You have the option to mark those records as
                                  paid, and the system will automatically
                                  transfer them to the Transaction history for
                                  tracking and record-keeping.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h4 className="accordion-header" id="collection_7">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collection_faq7"
                                aria-expanded="true"
                                aria-controls="collection_faq7"
                                data-bs-parent="#collectionReminder"
                              >
                                How can I recharge my Credits?
                              </button>
                            </h4>
                            <div
                              id="collection_faq7"
                              className="accordion-collapse collapse"
                              aria-labelledby="collection_7"
                              data-bs-parent="#collectionReminder"
                            >
                              <div className="accordion-body">
                                <p>
                                  Above the Credits section, you'll find a
                                  "Recharge" button. By clicking on it, you can
                                  input the desired number of credits for your
                                  recharge and proceed to the payment page.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="faq-tab-5"
                    role="tabpanel"
                    aria-labelledby="faq-tab5"
                  >
                    <div className="accordion" id="pos">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_1">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq1"
                                aria-expanded="true"
                                aria-controls="pos_faq1"
                                data-bs-parent="#pos"
                              >
                                How do I accept payment using the POS device?
                              </button>
                            </h4>
                            <div
                              id="pos_faq1"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_1"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  Machine will display options to scan QR, tap
                                  card or insert card. Accept payment through
                                  the customer's preferred mode.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_2">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq2"
                                aria-expanded="true"
                                aria-controls="pos_faq2"
                                data-bs-parent="#pos"
                              >
                                What should I do if the amount has been deducted
                                from the customer's account but the machine
                                shows transaction status as failed?
                              </button>
                            </h4>
                            <div
                              id="pos_faq2"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_2"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  The payment status on the machine is accurate.
                                  You should always provide services only if the
                                  status is successful. If an amount has been
                                  incorrectly deducted from the customer's
                                  account, you should inform the customer that
                                  it will be reversed in their account within
                                  5-7 business days.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_3">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq3"
                                aria-expanded="true"
                                aria-controls="pos_faq3"
                                data-bs-parent="#pos"
                              >
                                How can I view my transaction history?
                              </button>
                            </h4>
                            <div
                              id="pos_faq3"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_3"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  Click the History button on the POS device.It
                                  will show the transaction history. Select
                                  transaction and take print out also.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_4">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq4"
                                aria-expanded="true"
                                aria-controls="pos_faq4"
                                data-bs-parent="#pos"
                              >
                                Can I use my own SIM card in the EDC machine?
                              </button>
                            </h4>
                            <div
                              id="pos_faq4"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_4"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  Yes, You can use your own SIM card. Device
                                  also supports Wifi.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_5">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq5"
                                aria-expanded="true"
                                aria-controls="pos_faq5"
                                data-bs-parent="#pos"
                              >
                                What all payment modes can I use on this
                                machine?
                              </button>
                            </h4>
                            <div
                              id="pos_faq5"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_5"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  Device Support all payment modes. I.e
                                  VISA,Mastercard, AMEX, International, UPI/QR
                                  and EMI Payments.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_6">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq6"
                                aria-expanded="true"
                                aria-controls="pos_faq6"
                                data-bs-parent="#pos"
                              >
                                Do all your POS Machines have digital invoices?
                              </button>
                            </h4>
                            <div
                              id="pos_faq6"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_6"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  Yes, POS Machines support digital invoices via
                                  SMS and email. Add your customers email ID and
                                  mobile number to send them the invoice.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h4 className="accordion-header" id="pos_7">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#pos_faq7"
                                aria-expanded="true"
                                aria-controls="pos_faq7"
                                data-bs-parent="#pos"
                              >
                                When will I receive my transaction settlement?
                              </button>
                            </h4>
                            <div
                              id="pos_faq7"
                              className="accordion-collapse collapse"
                              aria-labelledby="pos_7"
                              data-bs-parent="#pos"
                            >
                              <div className="accordion-body">
                                <p>
                                  All your successful transactions will be
                                  settled within the Next day of transactions
                                  i.e T+1, incase bank holiday it will credit in
                                  your account next working day.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Faq;
